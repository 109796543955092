<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Please take a few minutes to answer the questions below regarding your experiences with the
        course. Thank you for being truthful in your responses and answering each question as fully
        as possible. Your TA needs to be made aware of their positive practices so they can continue
        to provide helpful instruction in the future. Equally important is ensuring the TA
        understands areas in need of improvement on their part. Your comments will be treated in
        confidence, as they will remain completely anonymous to your TA. Your responses are only
        used to improve the course and teaching in the future. You will receive 20 bonus points for
        completing this evaluation.
      </p>
      <p class="mb-3">
        Please choose your Lab Section # and TA Name (please read carefully as many TAs teach
        multiple sections)
      </p>

      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mb-0 ml-6">
        <v-radio
          v-for="option in options"
          :key="'pt-1-' + option.value"
          class="my-1 ml-3"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'OleMissSurveyQ0',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
      options: [
        {
          text: '115-1: Monday @ 2PM (Mariam Salifu)',
          value: '115-1',
        },
        {
          text: '115-2: Monday @ 2 PM (Simran Sony)',
          value: '115-2',
        },
        {
          text: '115-3: Tuesday @ 8 AM (Divyansh Prakash)',
          value: '115-3',
        },
        {
          text: '115-4: Tuesday @ 8 AM (Amal Ali)',
          value: '115-4',
        },
        {
          text: '115-5: Tuesday @ 11 AM (Divyansh Prakash)',
          value: '115-5',
        },
        {
          text: '115-6: Tuesday @ 11 AM (Hannah Mabie)',
          value: '115-6',
        },
        {
          text: '115-7: Tuesday @ 2:30 PM (Simran Sony)',
          value: '115-7',
        },
        {
          text: '115-8: Tuesday @ 2:30 PM (Nahid Akter)',
          value: '115-8',
        },
        {
          text: '115-9: Wednesday @ 11 AM (Sarah Basha)',
          value: '115-9',
        },
        {
          text: '115-10: Wednesday @ 11 AM (Ethan Jarrett)',
          value: '115-10',
        },
        {
          text: '115-11: Wednesday @ 2 PM (Mariam Salifu)',
          value: '115-11',
        },
        {
          text: '115-12: Wednesday @ 2 PM (Nahid Akter)',
          value: '115-12',
        },
        {
          text: '115-14: Thursday @ 8 AM (Amal Ali)',
          value: '115-14',
        },
        {
          text: '115-16: Thursday @ 11 AM (Sophia Prost)',
          value: '115-16',
        },
      ],
    };
  },
};
</script>
<style scoped></style>
